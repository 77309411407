import React, { useContext } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import useWindowSize from "../components/utils/use-window-size";
import Button from "../components/utils/button";
import { LanguageContext } from "../components/context/language-context";

const NotFoundPage = () => {
  const { isMobile } = useWindowSize();
  const { locale, t } = useContext(LanguageContext);
  return (
    <Layout languageMappings={{ default: "/", en: "/", de: "/" }}>
      <section
        // style={{
        //   background: isMobile
        //     ? "linear-gradient(148.83deg, #A71970 3.3%, #D72E38 48.84%, #F49235 96.77%)"
        //     : "linear-gradient(103.67deg, #A71970 8.35%, #D72E38 51.9%, #F49235 97.73%)",
        // }}
        className="bg-wsa-lightblue"
      >
        <div
          className="ws-cont grid place-content-center relative"
          style={{
            minHeight: isMobile ? "calc(100vh - 60px)" : "calc(100vh - 103px)",
          }}
        >
          <div className="flexc flex-col gap-8 py-9 px-10 text-white">
            <h1 className="font-bold text-4xl lg:text-[62px] text-center">
              {t["404_titolo"]}
            </h1>
            <p className="font-light text-xl lg:text-2xl text-center">
              {t["404_testo"]}
            </p>
            <div className="flexc justify-center gap-4">
              <Link to={"/" + (locale === "it" ? "" : locale)}>
                <Button
                  text={t["404_cta"]}
                  color="white"
                  underline="underline"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
